<template>
  <div id="search">
    <v-app-bar app color="white">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ restaurant.name }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <!-- Contact the waiter title -->
            <h3 class="headline mb-1">{{ $t('waiter.title') }}</h3>
          </v-col>

          <v-col cols="12" v-if="error != null">
            <v-alert type="error" border="left" colored-border elevation="2">
              <strong>{{ code }}</strong>
              {{ message }}
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-list flat subheader three-line>
              <v-subheader>{{ $t('waiter.options') }}</v-subheader>

              <v-list-item-group v-model="checkbox" mandatory active-class>
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t('waiter.callWaiter')
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t('waiter.callWaiterDescription')
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t('waiter.askForCheck')
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t('waiter.askForCheckDescription')
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-expand-transition>
              <v-list flat subheader three-line v-show="checkbox == 1">
                <v-subheader>{{ $t('waiter.paymentMethod') }}</v-subheader>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-cash-multiple</v-icon>
                  </v-list-item-action>

                  <v-list-item-content class="pt-0">
                    <v-list-item-title>
                      <v-select
                        class="pa-0"
                        v-model="payment_type"
                        :items="payments"
                        menu-props="auto"
                        single-line
                      ></v-select>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expand-transition>

            <v-card flat tile>
              <v-card-text>
                <v-textarea
                  outlined
                  v-model="details"
                  :label="$t('waiter.textareaLabel')"
                  :hint="$t('waiter.textareaHint')"
                  persistent-hint
                ></v-textarea>
              </v-card-text>
            </v-card>

            <v-alert
              :value="!isAuthorized"
              type="warning"
              border="left"
              class="my-4"
              colored-border
              elevation="2"
            >
              {{ $t('waiter.authorizationDetails1') }}
              <code class="key">{{ key }}</code>

              <br />
              <small>{{ $t('waiter.authorizationDetails2') }}</small>
            </v-alert>

            <v-btn
              block
              color="primary mb-3"
              v-if="!isAuthorized"
              @click="checkAuthorization"
            >
              <v-icon class="pr-2">mdi-account-convert</v-icon>
              {{ $t('cart.checkAuthorizationButton') }}
            </v-btn>

            <v-btn
              block
              color="primary"
              :disabled="!isAuthorized"
              @click="notify"
              >{{ $t('waiter.callWaiter') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Loading screen -->
    <easy-serve-loading :loading="loading"></easy-serve-loading>
  </div>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';
import EasyServeLoading from '../components/EasyServeLoading';

export default {
  mixins: [errors],

  components: {
    EasyServeLoading,
  },

  data: () => ({
    details: '',
    checkbox: 0,
    payment_type: 'cash',

    loading: false,
  }),

  computed: {
    ...mapGetters('security', {
      isAuthorized: 'isAuthorized',
      key: 'key',
    }),

    ...mapGetters('restaurant', {
      restaurant: 'restaurant', // Needed for the title
    }),

    payments() {
      return [
        {
          value: 'cash',
          text: this.$t('waiter.payments.cash'),
        },
        {
          value: 'card',
          text: this.$t('waiter.payments.card'),
        },
        {
          value: 'ticket',
          text: this.$t('waiter.payments.ticket'),
        },
      ];
    },
  },

  mounted() {},

  methods: {
    checkAuthorization() {
      this.$store.dispatch('restaurant/check');
    },

    notify() {
      // Reset all errors first
      this.resetErrors();

      // Start loading
      this.loading = true;

      // Checkbox 0 -> call Waiter
      // Checkbox 1 -> ask for the bill

      this.checkbox == 0 ? this.callWaiter() : this.askForBill();
    },

    callWaiter() {
      //
      this.$store
        .dispatch('waiter/call', this.details)
        .then(() => {
          // Go back and show a message,
          this.$router.push({ name: 'menu' });
          window.events.$emit(
            'snackbar',
            this.$t('waiter.notificationReceived')
          );
        })
        .catch(error => {
          // Show an error message and keep all data
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    askForBill() {
      const payload = {
        details: this.details,
        payment_type: this.payment_type,
      };

      this.$store
        .dispatch('bill/ask', payload)
        .then(() => {
          this.$router.push({ name: 'menu' });
          // Go back and show a message,
          window.events.$emit('snackbar', this.$t('waiter.askCheckReceived'));
        })
        .catch(error => {
          // Show an error message and keep all data
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetData() {
      this.checkbox = 0;
      this.payment = 'cash';
      this.details = '';
    },
  },
};
</script>

<style></style>
